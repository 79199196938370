import {useState} from "react";
import { FiMapPin, FiPhone, FiUser, FiGlobe, FiX, FiCheckCircle, FiXCircle } from 'react-icons/fi';
import { GrUpdate, GrTrash } from 'react-icons/gr'
import styled from "styled-components";
import Modal from 'react-modal';
import SnapshotsList from './snapshotsList'
import { colors, Routes } from "../constants";
import {httpRequest} from '../api/axios'
import { extractParams } from "../helpers";
import { Oval } from  'react-loader-spinner'

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxHeight: '80vh'
    },
    overlay: {
        background: '#00000080'
    }
};

Modal.setAppElement('#root');

const Row = styled.div`
    display: flex;
    align-items: center;
    padding: 2px 20px 2px 5px;
    border-radius: 20px;
    & p, & h5, & a {
        margin: 5px 0;
        margin-left: 10px;
        color: #1C0A00
    }
`

const Item = styled.div`
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: 0 5px 10px #d3d3d34a;
    display: flex;
    align-items: flex-end;
    &>div:first-child {
        width: 100%
    }
    & .actions {
        // height: 50px;
        // width: 200px;
        // background: red
    }
`

const Button = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background: ${props => props ? colors?.lightGreen: ''};
    border-radius: 20px;
    width: 150px;
    cursor: pointer;
    & p {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        margin-left: 10px;
        color: ${props => props ? colors?.green : ''} 
    }
`

function LocationCard(props) {

    const { locId: locationId, apiKey: custom_agencyApiKey } = extractParams();
    const itemData = props.item ? props.item : null
    const [modalIsOpen, setIsOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [locationID, setLocationID] = useState();
    const [modalContent, setModalContent] = useState('UPDATE_SNAPSHOT');
    const [hideDeletedItem, setHideDeletedItem] = useState('')
    const [seletedSnapshots, setSelectedSnapshots] = useState(0)

    if(itemData === null){
        return null
    }

    const deleteLocation = async () => {

        setIsDeleting(true)

        const deleting = await httpRequest(Routes.LOCATION_DELETE, 'post', null, {
            locationId,
            custom_agencyApiKey,
            locationToDelete: locationID
        })

        if(deleting){
            setIsDeleting(false)
            if(deleting.status){
                setModalContent('DELETE_SUCCESS')
                setTimeout(() => {
                    props.getList()
                    setIsOpen(false)
                    setModalContent('UPDATE_SNAPSHOT');
                    setHideDeletedItem('none')
                }, 2000);
            }else{
                console.log('shit', {deleting})
                setModalContent('DELETE_ERROR')
                setTimeout(() => {
                    setIsOpen(false)
                    setModalContent('UPDATE_SNAPSHOT');
                }, 3000);
            }
        }
    }

    const renderModalContent = () => {
        switch(modalContent){
            case 'DELETE_SUCCESS': {
                return (
                    <>
                        <div style={{ display: 'flex', alignItems:'center', marginBottom: 20 }}>
                            <h4 style={{ width: '100%', margin: 0 }}>
                                Delete Location
                            </h4>
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => { setIsOpen(false) }}
                            >
                                <FiX size={23} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center', width: 300, flexDirection: 'column' }}>
                            <FiCheckCircle color={colors.green} size={50} />
                            <p>
                                Location deleted successfully!
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: 30 }}>
                            <Button
                                onClick={()=>{
                                    setIsOpen(false)
                                }}
                                style={{
                                    width: 50, justifyContent: 'center',
                                }}
                            >
                                <p style={{ margin: 0 }}>
                                    Ok
                                </p>
                            </Button>
                        </div>    
                    </>
                )
            }
            case 'DELETE_ERROR': {
                return (
                    <>
                        <div style={{ display: 'flex', alignItems:'center', marginBottom: 20 }}>
                            <h4 style={{ width: '100%', margin: 0 }}>
                                Delete Location
                            </h4>
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => { setIsOpen(false) }}
                            >
                                <FiX size={23} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center', width: 300, flexDirection: 'column' }}>
                            <FiXCircle color={colors.red} size={50} />
                            <p>
                                There was an error while deleting this location, please refresh and try again!
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: 30 }}>
                            <Button
                                onClick={()=>{
                                    setIsOpen(false)
                                }}
                                style={{
                                    width: 50, justifyContent: 'center',
                                }}
                            >
                                <p style={{ margin: 0 }}>
                                    Ok
                                </p>
                            </Button>
                        </div>    
                    </>
                )
            }
            case 'DELETE_LOCATION': {
                return (
                    <>
                        <div style={{ display: 'flex', alignItems:'center', marginBottom: 20 }}>
                            <h4 style={{ width: '100%', margin: 0 }}>
                                Delete Location
                            </h4>
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => { setIsOpen(false) }}
                            >
                                <FiX size={23} />
                            </div>
                        </div>
                        <p>
                            Are you sure you want to delete this location ?
                        </p>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: 30 }}>
                            <Button
                                onClick={()=>{
                                    setIsOpen(false)
                                }}
                                style={{
                                    width: 50, justifyContent: 'center',
                                    marginRight: 10, 
                                    background: colors.Gray2
                                }}
                            >
                                <p style={{ margin: 0, color: "black", opacity: 0.5 }}>
                                    Cancel
                                </p>
                            </Button>
                            <Button
                                onClick={()=>{
                                    if(!isDeleting){
                                        deleteLocation()
                                    }
                                }}
                                style={{
                                    width: 50, justifyContent: 'center',
                                    padding: isDeleting ? '5px 20px' : '',
                                    opacity: isDeleting ? 0.5 : 1,
                                }}
                            >
                                {
                                    isDeleting ? 
                                        <Oval
                                            height="23"
                                            width="23"
                                            color={colors.green}
                                            ariaLabel='loading'
                                        />
                                    :
                                        <p style={{ margin: 0 }}>
                                            Confirm
                                        </p>
                                }
                            </Button>
                        </div>
                    </>
                )
            }
            case 'UPDATE_SNAPSHOT': {
                return(
                    <div style={{  }}>
                        <div style={{ display: 'flex', alignItems: 'center', }}>
                            <h4 style={{ width: '100%', margin: 0 }}>
                                Select a snapshot
                            </h4>
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => { setIsOpen(false) }}
                            >
                                <FiX size={23} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: '10px', marginBottom: 15 }}>
                            {
                                ['Vertical Snapshots','Imported Snapshots'].map((val, ind) => (
                                    <div 
                                        onClick={() => setSelectedSnapshots(ind)}
                                        style={{
                                            marginRight: 10,
                                            cursor: 'pointer',
                                            borderBottom: seletedSnapshots === ind ? '2px solid #000000' : '',
                                            transition: '0.1s'
                                        }}
                                    >
                                        <span style={{ fontSize: 14, fontWeight: seletedSnapshots === ind ? 600 : 300 }}>
                                            {val}
                                        </span>
                                    </div>
                                ))
                            }
                        </div>
                        <div style={{ minWidth: 600, minHeight: 300 }}>
                            <SnapshotsList 
                                closeSnapshotModal={()=>{ setIsOpen(false) }} 
                                locID={itemData.id} 
                                seletedSnapshots={seletedSnapshots} 
                            />
                        </div>
                    </div>
                )
            }
            default: {
                return(
                    <>
                        <div style={{ display: 'flex', alignItems:'center' }}>
                            <h4 style={{ width: '100%' }}>
                                Select a snapshot
                            </h4>
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => { setIsOpen(false) }}
                            >
                                <FiX size={23} />
                            </div>
                        </div>
                        <div style={{ minWidth: 300 }}>
                            <SnapshotsList closeSnapshotModal={()=>{ setIsOpen(false) }} locID={itemData.id} />
                        </div>
                    </>
                )
            }
        }
    }

    return(
        <Item style={{ display: locationID === itemData.id ? hideDeletedItem : '' }}>
            <div>
                {!!itemData.name && 
                    <Row 
                        style={{ 
                            background: '#EF6D6D20',
                            width: 'fit-content',
                            marginBottom: 20
                        }}
                    >
                        <FiGlobe />
                        <p 
                            style={{
                                color: colors.red,
                                margin: 0,
                                marginLeft: 10,
                            }}
                        >
                            {itemData.name}
                        </p>
                    </Row>
                }
                <Row>
                    <FiUser color={colors.green} />
                    <h5>
                        {itemData.firstName} {itemData.lastName} 
                    </h5>
                </Row>
                <Row>
                    <FiPhone color={colors.green} />
                    <p>
                        {itemData.phone}
                    </p>
                </Row>
                <Row>
                    <FiMapPin color={colors.green} />
                    <p>
                        {itemData.address}
                    </p>
                </Row>
            </div>
            <div className="actions">
                <Button
                    onClick={()=>{
                        setModalContent('UPDATE_SNAPSHOT')
                        setTimeout(() => {
                            setIsOpen(true)
                        }, 200);
                    }}
                    style={{
                        marginBottom: 10
                    }}
                >
                    <GrUpdate color={colors.green} />
                    <p>
                        Update Snapshot
                    </p>
                </Button>
                <Button
                    onClick={()=>{
                        setLocationID(itemData.id)
                        setModalContent('DELETE_LOCATION')
                        setTimeout(() => {
                            setIsOpen(true)
                        }, 200);
                    }}
                    style={{
                        background: colors.lightRed
                    }}
                >
                    <GrTrash color={colors.red} />
                    <p style={{ color: colors.red }}>
                        Delete Location
                    </p>
                </Button>
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={()=>setIsOpen(false)}
                style={customStyles}
                contentLabel="Example Modal"
                onAfterClose={() => {
                    setIsDeleting(false);
                    setLocationID();
                    setModalContent('UPDATE_SNAPSHOT');
                }}
            >
                {renderModalContent()}
            </Modal>
        </Item>
    )
}

export default LocationCard;
