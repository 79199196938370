import * as React from "react";
import Grid from "@mui/material/Grid";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import Typography from "@mui/material/Typography";
import { colors } from "../constants";
import deniedImage from '../assets/denied.png'

export const ErrorView = ({ message = "", description = "" }) => {
  return (
    <div className="App">
      <Grid
        container
        direction={"column"}
        style={{
          minHeight: "100%",
          backgroundColor: colors.Gray2,
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item style={{ marginBottom: "2vh" }}>
          {/* <NotInterestedIcon sx={{ fontSize: "12vw", color: colors.warning }} /> */}
          <img src={deniedImage} alt="" />
        </Grid>
        <Grid item>
          <Typography
            variant="h3"
            gutterBottom
            component="div"
            sx={{ color: colors.red, textAlign: "center", fontSize: "3vw" }}
          >
            {message}
          </Typography>
          <Typography
            variant="h3"
            gutterBottom
            component="div"
            sx={{ color: colors.red, textAlign: "center", fontSize: "1.5vw", fontWeight: '200', maxWidth: 800, opacity: 0.7 }}
          >
            {description}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default ErrorView;
