export const Routes = {
  CUSTOME_MENU_CHECK: "/custome-menu/check",
  LOCATIONS_LIST: "/custome-menu/locations-list",
  SNAPSHOTS_LIST: "/custome-menu/snapshots-list",
  SNAPSHOT_UPDATE: "/custome-menu/snapshots-update",
  GET_SETTINGS: "/get-settings",
  UPDATE_SETTINGS: "/update-settings",
  UPDATE_IMAGE: "/update-image",
  UPLOAD_IMAGE: "/upload-image",
  SAAS_DOMAIN_CHECK: "/saas-domain-check",
  SAAS_DOMAIN_ADD: "/saas-domain-add",
  PROSPECTING_CHECK: "/prospecting-check",
  PROSPECTING_CREATE: "/prospecting-create",
  LOCATION_DELETE: "/location-delete",
  DOMAIN_DELETE: "/saas-domain-delete",
  SUBLOCATION_CREATE: "/sublocation-create",
  SALES_AI: "sales-ai",
  SALES_AI_PRODUCT_CREATE: "sales-ai/products/create",
  SALES_AI_GOALS_CREATE: "sales-ai/goals/create",
  SALES_AI_ACCESS_CHECK: "sales-ai/access-check",
};

export default Routes;
