import React from "react";
import Iframe from "react-iframe";

export const IframeScreen = ({ link = '' }) => (
  <Iframe
    url={link}
    width="100%"
    height={document.documentElement.clientHeight}
    id="iframe"
    className="iframe"
    display="initial"
    position="relative"
  />
);

export default IframeScreen;
