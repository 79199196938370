import { useEffect, useState } from "react";
import {
  Routes,
  permissionErrorText,
  permissionErrorTitle,
  colors,
} from "../constants";
import { extractParams } from "../helpers";
import { httpRequest } from "../api/axios";
import { ErrorView, Loader } from "../views";
import { LocationCard, Prospecting } from "../components";
import styled from "styled-components";
import { FiPlus } from "react-icons/fi";
import { Button } from "../components/Button";
import CreateLocation from "./CreateLocation";

const Container = styled.div`
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 50px;
`;

export const SnapshotDeploymentScreen = () => {
  const { locId: locationId, apiKey: custom_agencyApiKey } = extractParams();
  const [loading, setLoading] = useState(false);
  const [createLocation, setCreateLocation] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    setLoading(true);
    const list = await httpRequest(Routes.LOCATIONS_LIST, "post", null, {
      locationId,
      custom_agencyApiKey,
    });
    if (list) {
      setLoading(false);
      setData(list.data);
    } else {
      setError(list);
    }
    console.log({ list });
  };

  const handleBack = () => {
    setCreateLocation(false);
    getList();
  };

  const renderActionBar = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <Button
          marginLeft={"10px"}
          onClick={() => {
            setCreateLocation(!createLocation);
          }}
          style={{
            border: "none",
            background: colors.lightGreen,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <FiPlus size={20} />
            <p style={{ marginLeft: 10 }}>Create sub location</p>
          </div>
        </Button>
        <Prospecting getList={getList} />
      </div>
    );
  };

  // permission denied view
  if (
    !locationId ||
    !custom_agencyApiKey ||
    error ||
    data?.authorized === false
  ) {
    return (
      <ErrorView
        message={permissionErrorTitle}
        description={permissionErrorText}
      />
    );
  }

  // loading view
  if (loading) return <Loader />;

  // main view
  return (
    <Container>
      {createLocation ? (
        <CreateLocation back={() => handleBack()} />
      ) : (
        <>
          {renderActionBar()}
          {!!data &&
            data?.map((item, index) => {
              return <LocationCard item={item} key={index} getList={getList} />;
            })}
        </>
      )}
    </Container>
  );
};

export default SnapshotDeploymentScreen;
