import { SnapshotDeploymentScreen, SettingsScreen, SalesAIScreen } from "../views";
import { extractParams } from "../helpers";

const { locId: locationId } = extractParams();

export const Menu = [
  {
    label: "Live Call Schedule",
    external: true,
    link: "https://calendar.google.com/calendar/u/0/embed?src=c_63577645d096ed19bacfefd45b0fc097ffd53a4b2616d676e2abfaa2e8b5f720@group.calendar.google.com&mode=WEEK&pli=1",
  },
  // {
  //   label: "LinkedIn Automation",
  //   external: true,
  //   link: `https://linkedific.leadific.io/?uid=${locationId}`,
  // },
  { label: "Location Management", external: false, view: SnapshotDeploymentScreen },
  { label: "Settings", external: false, view: SettingsScreen },
  { label: "Leadific Help Center", newTab: true, link: 'https://help.leadific.co/' },
  { label: "Import templates", newTab: true, link: 'https://templates.leadific.io/' },
  { label: "OmniPush", external: true, link: `https://op.leadific.io/?uid=${locationId}` },
  { label: "Sales AI", external: false, view: SalesAIScreen },
];

export default Menu;
