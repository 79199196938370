import React, { useEffect } from "react";
import "./App.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Menu, Routes, permissionErrorText, permissionErrorTitle } from "./constants";
import { IFram, ErrorView, Loader } from "./views";
import { RenderView, SideBarMenu } from "./components";
import useHttpRequest from "./api/actions";
import { extractParams } from "./helpers";
import { httpRequest } from "./api/axios";

function App() {
  const [selected, setSelected] = React.useState(0);
  const [link, setLink] = React.useState(Menu[0].link);

  const [menuItems, setMenuItems] = React.useState(Menu)

  const { locId: locationId, apiKey: custom_agencyApiKey } = extractParams();

  const [loading, data, error] = useHttpRequest({
    method: 'post',
    url: Routes.CUSTOME_MENU_CHECK,
    body: {
      locationId,
      custom_agencyApiKey,
    },
  });

  const getSalesAIAccessCheck = async () => {
    const salesai = await httpRequest(Routes.SALES_AI_ACCESS_CHECK, 'post', null, {
      locationId,
      custom_agencyApiKey,
    })
    
    var tmp = Menu
    if(!salesai?.data?.authorized){
      tmp = Menu.filter((val, ind) => val.label !== "Sales AI")
    }else{
      return null
    }
    setMenuItems(tmp)
  }

  useEffect(() => {
    getSalesAIAccessCheck()
  }, [])
  

  useEffect(() => {
    var tmp = Menu
    if(data){
      if(!data?.display_call_schedule){
        tmp = Menu.filter((val, ind) => val.label !== "Live Call Schedule")
      }
      if(!data?.display_linkedin_automation){
        tmp = tmp.filter((val, ind) => val.label !== "LinkedIn Automation")
      }
      setMenuItems(tmp)
    }
  }, [data])
  
  useEffect(() => {
    setLink(menuItems[0].link)
    setSelected(0)
  }, [menuItems])
  

  if (!locationId || !custom_agencyApiKey || error)
    return (
      <ErrorView
        message={permissionErrorTitle}
        description={permissionErrorText}
      />
    );

  if (loading) return <Loader />;

  if (data && !data?.authorized)
    return (
      <ErrorView
        message={permissionErrorTitle}
        description={permissionErrorText}
      />
    );

  return (
    <div className="App">
      <SideBarMenu
        Menu={menuItems}
        selected={selected}
        setSelected={setSelected}
        setLink={setLink}
        showLiveCall={!data?.display_call_schedule}
        showLinkedin={!data?.display_linkedin_automation}
      />
      <div className="content">
        {menuItems[selected].external ? (
          <IFram link={link} />
        ) : (
          RenderView(menuItems[selected].view)
        )}
      </div>
    </div>
  );
}

export default App;
