import styled from "styled-components"
import { Routes, colors } from "../constants"
import { FiAlertCircle, FiEdit, FiX } from "react-icons/fi"
import Modal from 'react-modal';
import { useState } from "react";
import { TextArea, TextInput } from "./textInput";
import { httpRequest } from "../api/axios";
import { extractParams } from "../helpers";
import { Oval } from "react-loader-spinner";

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxHeight: '80vh'
    },
    overlay: {
        background: '#00000080'
    }
};

Modal.setAppElement('#root');

const Card = styled.div`
    width: 100%;
    margint-bottom: 20px;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 5px 10px #d3d3d34a;
`

const Header = styled.div`
    width: 100%;
    padding: 10px 0;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1px solid #d3d3d34a;
    posittion: relative;
    .close {
        position: absolute;
        padding: 5px;
        top: 25px;
        right: 15px;
        cursor: pointer;
    }
`

const Body = styled.div`
    padding: 20px;
    max-height: 150px;
    overflow-y: scroll;
`

const Footer = styled.div`
    width: 100%;
    padding: 10px 0;
    border-top: 1px solid #d3d3d34a;
    display: flex;
    justify-content: flex-end;
`

const Button = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    background: ${props => props ? colors?.lightGreen: ''};
    border-radius: 20px;
    cursor: pointer;
    & p {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        color: ${props => props ? colors?.green : ''} 
    }
`

const Row = styled.div`
    display: flex;
    align-items: center;
    padding: 2px 20px 2px 5px;
    & label {
        color: #1C0A00;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
    }
    & div {
        color: #1C0A00;
        font-size: 14px;
        margin: 2px 0;
    }
`

const ModalContent = styled.div`
    display: flex; 
    flex-direction: column; 
    width: 450px;
    & input, & textarea {
        max-width: 420px;
        margin-bottom: 20px;
        margin-top: 5px;
    };
    .input-row {
        width: 100%;
    }
    .error {
        display: flex;
        flex: 1;
        gap: 8px;
        align-items: center;
        padding: 10px 20px;
        background: ${props => props && colors?.lightRed};
        border: 1px solid ${props => props && colors?.red};
        border-radius: 10px;
        margin-bottom: 20px;
        span {
            color: ${props => props && colors?.red};
            font-size: 14px;
            font-weight: 500;
        }
    }
`

const SalesAICard = ({refresh, data, isGoals, cardTitle}) => {
    const { locId: locationId, apiKey: custom_agencyApiKey } = extractParams();

    const [modalIsOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedItem, setSelectedItem] = useState()
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [link, setLink] = useState('')
    const [message, setMessage] = useState('')

    const clickEdit = (item) => {
        setSelectedItem(item)
        setTitle(item?.title)
        setDescription(item?.description)
        setLink(isGoals ? item?.link : '')
        setTimeout(() => {
            setIsOpen(true)
        }, 100);
    }

    const handleSave = async () => {
        if(loading){
            return null
        }

        setLoading(true)

        const body = {
            locationId,
            custom_agencyApiKey,
            title,
            description,
        }
        if(isGoals){
            body.link = link
        }

        const create = await httpRequest(
            isGoals ? Routes.SALES_AI_GOALS_CREATE : Routes.SALES_AI_PRODUCT_CREATE, 
            'post', 
            null, 
            body
        )

        if(create?.error?.response?.status === 400){
            setMessage(create?.error?.response?.data?.message)
            setLoading(false)
            return null
        }

        if(create){
            setLoading(false)
            if(create?.status){
                setMessage(create?.message)
                refresh()
                setTimeout(() => {
                    setIsOpen(false)
                }, 1000);
            }else{
                setMessage(create?.message)
            }
        }else{
            setLoading(false)
            setMessage('Something went wrong, please refresh and try again!')
        }
    }

    const renderModalContent = () => {
        return (
            <ModalContent>
                <Header>
                    {
                        selectedItem ?
                            isGoals ? 'Edit Strategy' : 'Edit Product'
                        :
                            isGoals ? 'Add New Strategy' : 'Add New Product'
                    }
                    <div className="close" onClick={() => setIsOpen(false)}><FiX size={18} /></div>
                </Header>
                <div className="input-row" style={{ marginTop: 20 }}>
                    <label>Title</label>
                    <TextInput
                        value={title}
                        onChange={(event)=>{
                            setTitle(event.target.value)
                        }}
                    />
                </div>
                <div className="input-row">
                    <label>Description</label>
                    <TextArea
                        rows={7}
                        value={description}
                        onChange={(event)=>{
                            setDescription(event.target.value)
                        }}
                    />
                </div>
                {isGoals && (
                    <div className="input-row">
                        <label>Goals URL</label>
                        <TextInput
                            value={link}
                            onChange={(event)=>{
                                setLink(event.target.value)
                            }}
                        />
                    </div>
                )}

                {
                    message !== '' && <div className="error"><FiAlertCircle size={18} color={colors.red} /><span>{message}</span></div>
                }
                <Footer>
                    <Button onClick={handleSave} style={{marginTop: 10, minWidth: 50, }}>
                        {
                            loading ?
                                <Oval
                                    height="16"
                                    width="16"
                                    color={colors.green}
                                    ariaLabel='loading'
                                />
                            :
                                <p>Save</p>
                        }
                    </Button>
                </Footer>
            </ModalContent>
        )
    }

    return (
        <Card>
            <Header>{cardTitle}</Header>
            <Body>
                <Row>
                    <label style={{width: 200}}>Title</label>
                    <label style={{flex: 1, display: 'flex'}}>Description</label>
                    {isGoals && <label style={{flex: 1, display: 'flex'}}>Link</label>}
                    <label style={{width: 60}}>Actions</label>
                </Row>
                {
                    data?.map(item => (
                        <Row key={item.id}>
                            <div style={{width: 200}}>{item?.title}</div>
                            <div style={{flex: 1, display: 'flex'}}>{item?.description}</div>
                            {isGoals && <div style={{flex: 1, display: 'flex'}}>{item?.link}</div>}
                            <div style={{width: 60}}>
                                <Button onClick={() => clickEdit(item)} style={{width: 30}}>
                                    <FiEdit />
                                </Button>
                            </div>
                        </Row>
                    ))
                }
            </Body>
            <Footer>
                <Button onClick={() => setIsOpen(true)} style={{marginTop: 10}}><p>Add New</p></Button>
            </Footer>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={()=>setIsOpen(false)}
                style={customStyles}
                contentLabel="Example Modal"
                onAfterClose={() => {
                    setSelectedItem()
                    setTitle('')
                    setDescription('')
                    setLink('')
                    setMessage('')
                    setLoading(false)
                }}
            >
                {renderModalContent()}
            </Modal>
        </Card>
    )
}

export default SalesAICard