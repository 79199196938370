import React, {useState} from "react";
import { FiAlertTriangle, FiArrowLeft, FiCheckCircle } from "react-icons/fi";
import styled from 'styled-components'
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { TextInput } from "../components/textInput";
import { colors, countries, Routes } from "../constants";
import { httpRequest } from "../api/axios";
import { extractParams } from "../helpers";
import PhoneInput from 'react-phone-input-2'
import TimezoneSelect from 'react-timezone-select'
import Modal from 'react-modal';
import { Button } from "../components/Button";
import { Oval } from "react-loader-spinner";
import Select from 'react-select'

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxHeight: 500,
    },
    overlay: {
        background: '#00000080',
        zIndex: 100000000
    }
};

const selectStyles = {
  control: base => ({
    ...base,
    height: 45,
    minHeight: 45,
    borderRadius: 10,
  }),
//   menuPortal: base => ({ ...base, zIndex: 999 })
};

Modal.setAppElement('#root');

let initialValues = {
    phone: '',
    firstName: "",
    lastName: "",
    email: "",
    businessName: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    timezone: "",    
    website: '',
    address: ''
};

const validation = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
    .email('Email address is invalid')
    .required('Email address is required'),
    phone: Yup.string()
            .min(11, 'Phone number is not valid')
            .matches(/^[+]{0,1}[-\s\./0-9]*$/, 'Phone number is not valid')
            .required('Phone number is required'),   
    businessName: Yup.string().required('Business name is required').nullable(),
    city: Yup.string().required('City is required').nullable(),
    state: Yup.string().required('State/Province is required').nullable(),
    postalCode: Yup.string().required('Postal Code is required').nullable(),
    country: Yup.string().required('Country name is required').nullable(),
    address: Yup.string().required('Address is required').nullable(),
    timezone: Yup.string().nullable(),
    website: Yup.string().nullable(),
});

const Container = styled.div`
    .back-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        padding: 10px 0;
        cursor: pointer
    }
    .back-button span {
        font-size: 18px;
        margin-left: 5px
    }
    .form-container {
        padding: 20px;
        margin-top: 20px;
        border-radius: 20px;
        box-shadow: 0 5px 10px #d3d3d34a;
    }
    .form {
        display: flex;
    }
    .form .column {
        width: 50%;
    }
    .error {
        font-size: 12px;
        color: ${props => props ? colors.red : 'tomato'};
        margin: 0;
        position: absolute;
        bottom: -15px;
        left: 15px
    }
    .input-container {
        margin-bottom: 35px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    .action {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .submit-button {
        width: 150px;
        padding: 10px;
        border: none;
        border-radius: 20px;
        background: ${props => props ? colors.lightGreen : ''};
        color: ${props => props ? colors.green : ''};
        font-weight: 600;
        font-size: 17px;
        cursor: pointer;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .special-label {
        display: none
    }
    .phone-input input, .timezone-select {
        width: 90%;
        max-width: 100%;
        min-width: 250px;
        height: 45px;
        padding: 0 15px;
        border: 1px solid lightgrey;
        border-radius: 10px;
        background: white
    }
    .timezone-select, .phone-input {
        width: 96.5%;
    }
    .phone-input {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .timezone-select>div {
        border-color: transparent;
        box-shadow: none;
        margin-top: 2px;
    }
    label {
        position: absolute;
        top: -10px;
        left: 20px;
        background: white;
        padding: 0 5px;
        font-size: 14px;
        z-index: 2;
    }
    label small {
        color: ${props => props ? colors.red : 'tomato'};
        font-size: 14px;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    input:focus {
        outline: none !important;
        border:1px solid ${props => props ? colors.green : ''};
    }
`

function CreateLocation({back}) {

    const { locId: locationId, apiKey: custom_agencyApiKey } = extractParams();
    const [message, setMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(null);
    const [open, setOpen] = useState(false);

    // create sub location
    const createSubLocation = async (data, isSubmitting)  => {

        let body = {
            locationId,
            custom_agencyApiKey,
            phone: data.phone,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            businessName: data.businessName,
            city: data.city,
            state: data.state,
            postalCode: data.postalCode,
            country: data.country,
            timezone: data.timezone,    
            website: data.website,
            address: data.address
        }

        const createLocation = await httpRequest(Routes.SUBLOCATION_CREATE, 'post', null, body)

        if(createLocation){
            isSubmitting(false);
            if(createLocation.status){
                setOpen(true)
                setIsSuccess(true)
                setMessage(createLocation.message)
            }else{
                setIsSuccess(false)
                setMessage(createLocation.error?.response?.data?.message)
                if(createLocation.error?.response?.status !== 422){
                    setOpen(true)
                }
            }
        }
    }      

    return(
        <Container>
            <div className="back-button" onClick={() => back()}>
                <FiArrowLeft size={18} /> <span>Back</span>
            </div>

            <div className="form-container">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validation}
                    enableReinitialize
                    onSubmit={(values, { setSubmitting }) => {
                        createSubLocation(values, setSubmitting)
                    }}
                >
                    {({ values, errors, touched, setFieldValue, handleChange, handleBlur, isSubmitting }) => (
                        <Form>
                            <div className="form">
                                <div className="column">
                                    <div className="input-container">
                                        <label>
                                            First name <small>*</small>
                                        </label>
                                        <TextInput 
                                            type="firstName"
                                            name="firstName"
                                            placeholder="John"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.firstName}
                                            maxWidth={'90%'}
                                        />
                                        <p className="error">
                                            {errors.firstName && touched.firstName && errors.firstName}
                                        </p>
                                    </div>
                                    <div className="input-container">
                                        <label>
                                            Last name <small>*</small>
                                        </label>
                                        <TextInput 
                                            type="lastName"
                                            name="lastName"
                                            placeholder="Doe"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.lastName}
                                            maxWidth={'90%'}
                                        />
                                        <p className="error">
                                            {errors.lastName && touched.lastName && errors.lastName}
                                        </p>
                                    </div>
                                    <div className="input-container">
                                        <label>
                                            Email <small>*</small>
                                        </label>
                                        <TextInput 
                                            type="email"
                                            name="email"
                                            placeholder="example@mail.com"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            maxWidth={'90%'}
                                        />
                                        <p className="error">
                                            {errors.email && touched.email && errors.email}
                                        </p>
                                    </div>
                                    <div className="input-container">
                                        <label>
                                            Phone <small>*</small>
                                        </label>
                                        <PhoneInput
                                            country={'us'}
                                            placeholder="+1 (453) 453-4535"
                                            enableSearch={ true }
                                            inputProps={{
                                                name: 'phone',
                                            }}
                                            value={values.phone}
                                            disableSearchIcon = { true }
                                            onChange= { (value) => {
                                                setFieldValue('phone', value)
                                            }}
                                            className="phone-input"
                                        />
                                        <p className="error">
                                            {errors.phone && touched.phone && errors.phone}
                                        </p>
                                    </div>
                                    <div className="input-container">
                                        <label>
                                            Business name <small>*</small>
                                        </label>
                                        <TextInput 
                                            type="businessName"
                                            name="businessName"
                                            placeholder="Business..."
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.businessName}
                                            maxWidth={'90%'}
                                        />
                                        <p className="error">
                                            {errors.businessName && touched.businessName && errors.businessName}
                                        </p>
                                    </div>
                                    <div className="input-container">
                                        <label>
                                            Address <small>*</small>
                                        </label>
                                        <TextInput 
                                            type="address"
                                            name="address"
                                            placeholder="Address"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.address}
                                            maxWidth={'90%'}
                                        />
                                        <p className="error">
                                            {errors.address && touched.address && errors.address}
                                        </p>
                                    </div>
                                </div>
                                <div  className="column">
                                    <div className="input-container">
                                        <label>
                                            City <small>*</small>
                                        </label>
                                        <TextInput 
                                            type="city"
                                            name="city"
                                            placeholder="City"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.city}
                                            maxWidth={'90%'}
                                        />
                                        <p className="error">
                                            {errors.city && touched.city && errors.city}
                                        </p>
                                    </div>
                                    <div className="input-container">
                                        <label>
                                            State <small>*</small>
                                        </label>
                                        <TextInput 
                                            type="state"
                                            name="state"
                                            placeholder="State"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.state}
                                            maxWidth={'90%'}
                                        />
                                        <p className="error">
                                            {errors.state && touched.state && errors.state}
                                        </p>
                                    </div>
                                    <div className="input-container">
                                        <label>
                                            Postal Code <small>*</small>
                                        </label>
                                        <TextInput 
                                            type="postalCode"
                                            name="postalCode"
                                            placeholder="Postal Code"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.postalCode}
                                            maxWidth={'90%'}
                                        />
                                        <p className="error">
                                            {errors.postalCode && touched.postalCode && errors.postalCode}
                                        </p>
                                    </div>
                                    <div className="input-container">
                                        <label>
                                            Country <small>*</small>
                                        </label>
                                        <div style={{ width: "95%", zIndex: 1, position: 'relative' }}>
                                        <Select 
                                            options={countries} 
                                            name={values.country}
                                            value={countries ? countries.find(option => option.value === values.country) : ''}
                                            onChange={(option) => setFieldValue('country', option.value)}
                                            onBlur={handleBlur}
                                            menuPortalTarget={document.body} 
                                            styles={selectStyles}
                                        />
                                        </div>
                                        <p className="error">
                                            {errors.country && touched.country && errors.country}
                                        </p>
                                    </div>
                                    <div className="input-container">
                                        <label style={{ zIndex: 1 }}>
                                            Timezone <small>*</small>
                                        </label>
                                        <TimezoneSelect
                                            value={values.timezone}
                                            onChange={ (value) => {
                                                setFieldValue('timezone', value.value)
                                            }}
                                            className={'timezone-select'}
                                        />
                                        <p className="error">
                                            {errors.timezone && touched.timezone && errors.timezone}
                                        </p>
                                    </div>
                                    <div className="input-container">
                                        <label style={{ zIndex: 1 }}>
                                            Website <small>*</small>
                                        </label>
                                        <TextInput 
                                            type="website"
                                            name="website"
                                            placeholder="website.com"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.website}
                                            maxWidth={'90%'}
                                        />
                                        <p className="error">
                                            {errors.website && touched.website && errors.website}
                                        </p>
                                    </div>
                                </div>
                            </div>       
                            <div className="action">
                                <div>
                                    {!isSuccess && <p style={{ color: colors.red, margin: 0, fontSize: 15, whiteSpace: 'pre-line', marginLeft: 20 }}>
                                        {message}
                                    </p>}
                                </div>
                                <button disabled={isSubmitting} className="submit-button" type="submit">
                                    {
                                        isSubmitting ?
                                            <Oval
                                                height="23"
                                                width="23"
                                                color={colors.green}
                                                ariaLabel='loading'
                                            />
                                        :
                                            'Submit'
                                    }
                                </button>
                            </div>
                        </Form>
                    )} 
                </Formik>
            </div>

            <Modal
                isOpen={open}
                onRequestClose={()=>setOpen(false)}
                style={customStyles}
                onAfterClose={() => {
                    setMessage('')
                    setIsSuccess(null)
                }}
            >
                <div style={{ maxWidth: 400, minWidth: 300 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {
                            isSuccess ?
                                <FiCheckCircle size={50} color={colors.green} />
                            :
                                <FiAlertTriangle size={50} color={colors.red} />
                        }
                        <p>
                            {message}
                        </p>
                    </div>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={() => {
                                if(isSuccess){
                                    back()
                                }else{
                                    setOpen(false)
                                }
                            }}
                            style={{
                                border: 'none',
                                background: colors.lightGreen
                            }}
                        >
                            <p>
                                {isSuccess ? 'Continue' : 'Ok'}
                            </p>
                        </Button>
                    </div>
                </div>
            </Modal>
        </Container>
    )
}

export default CreateLocation;