import { useState } from 'react'
import Modal from 'react-modal'
import { FiX, FiCheck, FiInfo } from 'react-icons/fi'
import styled from 'styled-components'
import { Routes, colors } from "../constants"
import ReactPlayer from 'react-player'
import { extractParams } from "../helpers"
import {httpRequest} from '../api/axios'
import { Oval } from 'react-loader-spinner'

const Close = styled.div`
    position: absolute; top: 20px; right: 20px;
    width: 30px; height: 30px;
    display: flex; align-items: center;
    justify-content: center;
    cursor: pointer;
`

const AddDomain = styled.div`
    & h2 {
        font-weight: 400;
        margin: 0;
    };
    & p {
        max-width: 500px;
        font-size: 14px;
        line-height: 22px
    };
    & input {
        width: fill-available; height: 45px;
        padding: 0 15px;
        border: 1px solid lightgrey;
        border-radius: 10px;
        margin: 10px 0;
    };
    & .actions {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 20px
    }
`

const Button = styled.div`
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    background: ${props => props ? colors?.lightGreen : ''};
    border-radius: 20px;
    cursor: pointer;
    & p {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        color: ${props => props ? colors?.green : ''}
    }
`

const StatusContainer = styled.div`
    & h2 {
        font-weight: 400;
        margin: 0;
    };
    & p {
        max-width: 500px;
        font-size: 16px;
        line-height: 22px
    };
    & .actions {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 20px
    };
    & .message {
        display: flex; flex-direction: column;
        align-items: center;
        margin-top: 20px
    };
    & .message>div {
        width: 50px; height: 50px;
        min-width: 50px; min-height: 50px;
        background: ${props => props ? colors?.green : ''};
        display: flex; justify-content: center;
        align-items: center;
        border-radius: 30px
    }
`

const Verify = styled.div`
    & h2, & h4 {
        font-weight: 400;
        margin: 0;
    };
    & p {
        max-width: 500px;
        font-size: 14px;
        line-height: 22px;
    };
    & span {
        max-width: 500px;
        font-size: 14px;
        font-weight: 500
    };
    & .actions {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 20px
    };
    & .dns-records {
        width: 100%
    };
    & .dns-records>div, & .subdomain-info>div {
        padding: 15px 20px;
        background: ${props => props ? colors?.lightGreen : ''};
        border-radius: 10px;
        margin-bottom: 10px;
        opacity: 0.5;
    };
    & .subdomain-info {
        display: flex; 
        justify-content: space-between;
        width: 100%
    };
    & .subdomain-info>div {
        margin-right: 10px
    }
`

const Info = styled.div`
    position: absolute;
    left: 20px; bottom: 20px;
    display: flex; align-items: center;
    cursor: pointer;
    & span {
        margin-left: 10px;
        color: ${props => props ? colors?.warning : ''};
    }
`

const Video = styled.div`
    & h2 {
        font-weight: 400;
        margin: 0;
    };
    & p {
        max-width: 500px;
        font-size: 14px;
        line-height: 22px
    };
    & .actions {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    };
    & iframe {
        padding: 0 !important
    }
`

const Loading = styled.div`
    width: 100%;
    height: 100%;
    display: flex; align-items: center;
    justify-content: center;
    position: absolute;
    top: 0; left: 0
`

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxHeight: 550,
      maxWidth: 900,
      minWidth: 320,
      borderRadius: 20
    },
    overlay: {
        background: '#00000080'
    }
};

Modal.setAppElement('#root');

const SaasDomainModal = (props) => {

    const { locId: locationId, apiKey: custom_agencyApiKey } = extractParams();
    const [domain, setDomain] = useState('')
    const [status, setStatus] = useState('')
    const [error, setError] = useState('')
    const [loadingVideo, setLoadingVideo] = useState(true)
    const [checkingDomain, setCheckingDomain] = useState(false)
    const [addingDNSRecords, setAddingDNSRecords] = useState(false)
    const [dnsRecords, setDnsRecords] = useState()
    const urlRegex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');

    const validateURL = (value) => {
        if (!urlRegex.test(value)) {
           return false
        } else {
            return true
        }
    };

    // api call to check saas domain
    const checkSaasDomain = async ()  => {

        setCheckingDomain(true)

        const check = await httpRequest(Routes.SAAS_DOMAIN_CHECK, 'post', null, {
            locationId,
            custom_agencyApiKey,
            saasDomain: domain
        })

        if(check){
            setCheckingDomain(false)
            if(check?.status){
                setDnsRecords(check?.data?.nsRecords)
                setTimeout(() => {
                    setStatus('VERIFY')
                }, 100);
            }else {
                setError(check?.error?.response?.data?.message || check?.message)
                setTimeout(() => {
                    setStatus('ERROR')
                }, 100);
            }
        }
    }

    // add dns records to verify domain
    const addDNSRecords = async ()  => {

        setAddingDNSRecords(true)

        const add = await httpRequest(Routes.SAAS_DOMAIN_ADD, 'post', null, {
            locationId,
            custom_agencyApiKey,
            saasDomain: domain
        })

        if(add){
            setAddingDNSRecords(false)
            if(add?.status){
                setTimeout(() => {
                    setStatus('SUCCESS')
                }, 100);
            }else {
                setError(add?.error?.response?.data?.message)
                setTimeout(() => {
                    setStatus('ERROR')
                }, 100);
            }
        }
    }

    // render modal content depending on status
    const renderContent = () => {
        switch(status){
            case 'SUCCESS': {
                return(
                    <StatusContainer>
                        <h2>
                            SUCCESS
                        </h2>
                        <div className='message'>
                            <div>
                                <FiCheck size={35} color={'#ffffff'} />
                            </div>
                            <p>
                                {domain} is verified!
                            </p>
                        </div>
                        <div className='actions'>
                            <Button
                                onClick={()=>{
                                    props.close()
                                    setStatus('')
                                }}
                            >
                                <p>
                                    Done
                                </p>
                            </Button>
                        </div>
                    </StatusContainer>
                )
            }
            case 'ERROR': {
                return(
                    <StatusContainer>
                        <h2>
                            ERROR
                        </h2>
                        <div className='message'>
                            <div style={{ background: colors.lightRed }}>
                                <FiX size={35} color={'#ffffff'} />
                            </div>
                            <p>
                                {error}
                            </p>
                        </div>
                        <div className='actions'>
                            <Button
                                onClick={()=>{
                                    props.close()
                                    setStatus('')
                                }}
                                style={{
                                    background: colors.lightRed
                                }}
                            >
                                <p style={{ color: colors.red }}>
                                    OK
                                </p>
                            </Button>
                        </div>
                    </StatusContainer>
                )
            }
            case 'VERIFY': {
                return(
                    <Verify>
                        <h2>
                            VERIFY YOUR HOSTNAME
                        </h2>
                        <h4 style={{ marginTop: 30 }}>
                            DNS NS RECORDS
                        </h4>
                        <p style={{ opacity: 0.5 }}>
                            You must now add a NS record to your DNS pointing your hostname at our DNS servers below. 
                        </p>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'flex-start', width: 700, marginTop: 30 }}>
                            <div className='subdomain-info'>
                                <div style={{ width: '45%' }}>
                                    <span>
                                        {domain?.split('.')[0]}
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        NS
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        3600
                                    </span>
                                </div>
                            </div>
                            <div className='dns-records'>
                                {
                                    !!dnsRecords && dnsRecords?.map((val, ind)=>{
                                        return(
                                            <div key={ind}>
                                                <span>
                                                    {ind+1}. {val}
                                                </span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className='actions'>
                            <Button
                                onClick={()=>{
                                    addDNSRecords()
                                }}
                            >
                                {
                                    addingDNSRecords ? 
                                        <Oval 
                                            height="23"
                                            width="23"
                                            color={colors.green}
                                            ariaLabel='loading'
                                        />
                                    :
                                        <p>
                                            Verify
                                        </p>
                                }
                            </Button>
                        </div>

                        <Info
                            onClick={()=>{
                                setStatus('HOWTO')
                            }}
                        >
                            <FiInfo color={colors?.warning} />
                            <span>
                                How to update dns settings?
                            </span>
                        </Info>
                    </Verify>
                )
            }
            case 'HOWTO': {
                return(
                    <Video>
                        <h2>
                            HOW TO ADD THE NS RECORDS
                        </h2>
                        <ReactPlayer 
                            url='https://vimeo.com/685450848/973c9bc524' 
                            height={400}
                            width={600}
                            controls
                            onReady={()=>{
                                setLoadingVideo(false)
                            }}
                        />
                        <div className='actions'>
                            <Button
                                onClick={()=>{
                                    // props.close()
                                    setStatus('VERIFY')
                                }}
                            >
                                <p>
                                    Back
                                </p>
                            </Button>
                        </div>
                        {loadingVideo && <Loading>
                            <Oval 
                                height="30"
                                width="30"
                                color={colors.green}
                                ariaLabel='loading'
                            />
                        </Loading>}
                    </Video>
                )
            }
            default: {
                return(
                    <AddDomain>
                        <h2>
                            ADD A DOMAIN
                        </h2>
                        <p>
                            In order to white label Leadific to your own brand, you must provide us with the hostname you will use for your SaaS brand. An example would be: app.medspacrm.com
                        </p>

                        <input 
                            value={domain}
                            placeholder='Enter your hostname'
                            onChange={(event)=>{
                                setDomain(event.target.value)
                            }}
                        />
                        <div className='actions'>
                            <Button
                                onClick={()=>{
                                    if(domain && validateURL(domain)){
                                        checkSaasDomain()
                                    }
                                }}
                                style={{
                                    background: (domain && validateURL(domain)) ? colors.lightGreen : 'lightgrey'
                                }}
                            >
                                {
                                    checkingDomain ? 
                                        <Oval 
                                            height="23"
                                            width="23"
                                            color={colors.green}
                                            ariaLabel='loading'
                                        />
                                    :
                                        <p
                                            style={{
                                                color: (domain && validateURL(domain)) ? colors.green : 'white'
                                            }}
                                        >
                                            Add
                                        </p>
                                }
                            </Button>
                        </div>
                    </AddDomain>
                )
            }
        }
    }
    
    return(
        <Modal
            isOpen={props.open}
            onRequestClose={()=>{
                props.close()
                setStatus('')
            }}
            style={customStyles}
            contentLabel="SAAS Domain Modal"
        >
            {renderContent()}
            <Close 
                onClick={()=>{
                    props.close()
                    setStatus('')
                }}
            >
                <FiX size={23} />
            </Close>
        </Modal>
    )
}
  
  export default SaasDomainModal;
  