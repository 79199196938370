import styled from 'styled-components'
import { colors } from '../constants'

const Button = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 20px;
    min-width: 80px;
    cursor: pointer;
    margin-left: ${props => props.marginLeft ? props.marginLeft : ''};
    & p {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        text-align: center;
        width: 100%;
        color: ${props => props ? colors?.green : ''} 
    }
`

export {Button}