import { useCallback, useEffect, useState } from "react";
import { httpRequest } from "./axios";

export const useHttpRequest = ({ url, method, headers, body }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const [refetch, setRefetch] = useState(false);

  const RefetchData = () => setRefetch((v) => !v);

  const request = useCallback(async ({ url, method, headers, body }) => {
    const response = await httpRequest(url, method, headers, body);

    if (response?.status) setData(response.data);
    else setError(response?.message || 'Something went wrong.');

    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    setData(null);
    setError(null);

    request({ url, method, headers, body });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, method, request, refetch]);

  return [loading, data, error , RefetchData];
};

export default useHttpRequest;
