export const SideBarMenu = ({ Menu = [], selected, setSelected, setLink }) => (
  <div className="menu">
    <div>
      Agency Admin
    </div>
    {Menu.map((val, ind) => {
      return (
        <div
          key={ind}
          onClick={() => {
            if(val.newTab){
              window?.open(val.link);
            }else{
              setSelected(ind);
              setLink(val.link);
            }

          }}
          style={{
            fontWeight: ind === selected ? 700 : "",
            borderBottom: ind === selected ? "2px solid #000000" : "none",
          }}
        >
          {val.label}
        </div>
      );
    })}
  </div>
);

export default SideBarMenu;
