import {useEffect, useState} from "react";
import { Routes, permissionErrorText, permissionErrorTitle } from "../constants";
import { extractParams } from "../helpers";
import { ErrorView, Loader } from "../views";
import styled from "styled-components";
import SalesAICard from "../components/salesAICard";
import { httpRequest } from "../api/axios";

const Container = styled.div`
  padding: 30px;
  max-width: 1000px;
  margin: 0 auto;
`

const mockData = [
  {id: 1, title: 'test 1', description: 'description', link: "https://react-icons.github.io/"}, 
  // {id: 2, title: 'test 2', description: 'description', link: "https://react-icons.github.io/"}, 
  // {id: 3, title: 'test 3', description: 'description', link: "https://react-icons.github.io/"},
  // {id: 4, title: 'test 4', description: 'description', link: "https://react-icons.github.io/"},
]

export const SalesAIScreen = () => {

  const { locId: locationId, apiKey: custom_agencyApiKey } = extractParams();
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    setLoading(true)
    const salesai = await httpRequest(Routes.SALES_AI, 'post', null, {
      locationId,
      custom_agencyApiKey,
    })
    if(salesai){
      setLoading(false)
      setData(salesai.data)
    }else{
      setError(salesai)
    }
  }

  // permission denied view
  if (!locationId || !custom_agencyApiKey || error){
    return (
      <ErrorView
        message={permissionErrorTitle}
        description={permissionErrorText}
      />
    );
  }

  // loading view
  if (loading) return <Loader />;

  // main view
  return (
    <Container>
      <h2>Sales AI Setup</h2>
      <SalesAICard refresh={getData} data={data?.products || mockData} cardTitle="Your Products" />
      <SalesAICard refresh={getData} data={data?.goals || mockData} cardTitle="Your Goals" isGoals />
    </Container>
  );
};

export default SalesAIScreen;
