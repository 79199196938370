import { useState, useEffect } from "react";
import { FiX, FiPlus } from 'react-icons/fi';
import styled from "styled-components";
import { colors, Routes } from "../constants";
import { extractParams } from "../helpers";
import {httpRequest} from '../api/axios'
import { Oval } from "react-loader-spinner";
import Modal from 'react-modal';
import { Button } from "./Button";

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxHeight: 500,
      maxWidth: 500
    },
    overlay: {
        background: '#00000080'
    }
};

Modal.setAppElement('#root');

const Container = styled.div`
    display: flex;
    justify-content: flex-end;
`

const Prospecting = (props) => {

    const [prospecting, setProspecting] = useState(false)
    const [showButton, setShowButton] = useState(false)
    const [message, setMessage] = useState({success: false, message: ''})
    const [modalIsOpen, setIsOpen] = useState(false)
    const { locId: locationId, apiKey: custom_agencyApiKey } = extractParams();
    useEffect(() => {
      check()
    }, [])

    // check if to display button
    const check = async () => {

        const check = await httpRequest(Routes.PROSPECTING_CHECK, 'post', null, {
            locationId,
            custom_agencyApiKey,
            saasDomain: window?.location?.hostname,
        })

        if(check){
            if(check?.data?.canCreate){
                setShowButton(true)
            }else{
                setShowButton(false)
            }
        }else{
            setShowButton(false)
        }
    }

    // prospecting button click handler
    const click = async () => {

        setProspecting(true)

        const update = await httpRequest(Routes.PROSPECTING_CREATE, 'post', null, {
            locationId,
            custom_agencyApiKey,
            saasDomain: window?.location?.hostname,
        })

        if(update){
            setProspecting(false)
            if(update?.status){
                setMessage({success: true, message: update?.message})
                setTimeout(() => {
                    props.getList()
                    check()
                }, 2000);
            }else{
                setMessage({success: false, message: update?.message})
            }
        }else{
            setMessage({success: false, message: 'Something went wrong, please refresh and try again!'})
        }
    }

    if(showButton){
        return(
            <Container>
                <Button
                    marginLeft={'10px'}
                    onClick={() => {
                        setIsOpen(true)
                    }}
                    style={{
                        border: 'none',
                        background: colors.lightGreen
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FiPlus size={20} />
                        <p style={{ marginLeft: 10 }}>
                            Prospecting
                        </p>
                    </div>
                </Button>

                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={()=>setIsOpen(false)}
                    style={customStyles}
                    contentLabel="Example Modal"
                    onAfterClose={() => {
                        setMessage({success: false, message: ''})
                        setProspecting(false)
                    }}
                >
                    <div style={{ display: 'flex', alignItems:'center' }}>
                        <h4 style={{ width: '100%', margin: 0 }}>
                            Create prospecting location
                        </h4>
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => { setIsOpen(false) }}
                        >
                            <FiX size={23} />
                        </div>
                    </div>
                    <div style={{ minWidth: 300, margin: '30px 0', display: 'flex', justifyContent: 'center' }}>
                        {
                            message?.message ?
                                <p>
                                    {message?.message}
                                </p>
                            :
                                <p>
                                    Are you sure you want to create a sub location for prospecting bandit? This will count towards your account utilization.
                                </p>
                        }
                    </div>
                    {!message?.message && <div
                        style={{
                            display: 'flex', alignItems: 'center',
                            justifyContent: 'flex-end', width: '100%',
                            marginTop: 20
                        }}
                    >
                        <Button
                            onClick={()=>{
                                setIsOpen(false)
                            }}
                            style={{
                                marginRight: 10,
                                border: `1px solid ${colors.warning}`
                            }}
                        >
                            <p style={{ color: colors.warning }}>
                                Cancel
                            </p>
                        </Button>
                        <Button
                            onClick={()=>{
                                if(!prospecting){
                                    click()
                                }
                            }}
                            style={{
                                border: 'none',
                                background: colors.lightGreen,
                                display: 'flex', alignItems: 'center', justifyContent: 'center',
                                padding: prospecting ? '5px 20px' : '',
                                opacity: prospecting ? 0.5 : 1,
                            }}
                        >
                            {
                                prospecting ?
                                    <Oval
                                        height="22"
                                        width="22"
                                        color={colors.Gray2}
                                        ariaLabel='loading'
                                    />
                                :
                                    <p
                                        style={{
                                            color: colors.green
                                        }}
                                    >
                                        Continue
                                    </p>
                            }
                        </Button>
                    </div>}

                    {
                        message?.message && <div
                            style={{
                                display: 'flex', alignItems: 'center',
                                justifyContent: 'flex-end', width: '100%',
                                marginTop: 20
                            }}
                        >
                            <Button
                                onClick={()=>{
                                    setIsOpen(false)
                                    // props.getList()
                                }}
                                style={{
                                    marginRight: 10,
                                    border: message?.success ? 'none' : `1px solid ${colors.red}`,
                                    width: 50,
                                    background: message?.success ? colors.lightGreen : ''
                                }}
                            >
                                <p style={{ color: message?.success ? colors.green : colors.red }}>
                                    Ok
                                </p>
                            </Button>
                        </div>
                    }
                </Modal>
            </Container>
        )
    }else{
        return null
    }
}

export default Prospecting;
