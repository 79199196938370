import React, { useEffect } from "react";
import { FiAlertTriangle, FiArrowRight, FiX, FiEye } from 'react-icons/fi';
import styled from "styled-components";
import { Routes, colors } from "../constants";
import useHttpRequest from "../api/actions";
import {httpRequest} from '../api/axios'
import { extractParams } from "../helpers";
import Modal from 'react-modal';
import { Oval } from  'react-loader-spinner'
import Placeholder from '../assets/placeholder-illu.svg'
import EmptyIllu from '../assets/empty-list.svg'
import ReactPlayer from 'react-player/youtube'
import parse from 'html-react-parser'
import NothingFound from "../assets/nothing-found.png";
import CircularProgress from "@mui/material/CircularProgress";
// import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  background: '#FFFFFF'
};

const types = ['DEFAULT', 'IMPORTED']

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {
        background: '#00000002'
    }
};

Modal.setAppElement('#root');

const GridContainer = styled.div`
    max-width: 1200px;
    min-width: 800px;
    min-height: 500px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    // position: relative;
    .empty-list {
        width: 800px;
        height: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .empty-list img {
        width: 50%;
    }
    .empty-list h4 {
        margin: 0;
        font-size: 22px;
        font-weight: 500
    }
`

const Row = styled.div`
    width: 100%;
    height: 100%;
    .illustration {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .illustration img {
        width: 90%;
        height: 160px;
    }
    & p, & h5, & a {
        margin: 5px 0;
        margin-left: 10px;
        color: #1C0A00
    }
`

const Item = styled.div`
    padding: 10px;
    width: 230px;
    height: 200px;
    margin: 5px;
    // position: relative;
    border: 1px solid #d3d3d34a;
    &>div:first-child {
        width: 100%
    }
    .actions {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #00000080;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        opacity: 0;
        transition: 0.3s
    }
    &:hover .actions {
        opacity: 1
    }
`

const Button = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    background: #008E89;
    border-radius: 20px;
    cursor: pointer;
    width: 100px;
    & p {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        color: #FFFFFF;
        text-align: center;
        margin-right: 10px
    }
`

const SnapshotsList = (props) => {

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [confirm, setConfirm] = React.useState("");
    const [selectedSnapshot, setSelectedSnapshot] = React.useState();
    const [updating, setUpdating] = React.useState(false);
    const [snapshots, setSnapshots] = React.useState([])
    const [selectedDetail, setSelectedDetail] = React.useState(0)
    const [isLoading, setIsLoading] = React.useState(true)

    const [openSnapshotDetails, setOpenSnapshotDetails] = React.useState(false)

    const refModal = React.useRef();
    const refScrollable = React.useRef();

    React.useEffect(() => {
        const handleClick = (event) => {
        if (refModal.current && !refModal.current.contains(event.target)) {
            setSelectedDetail(0)
            // 
            setOpenSnapshotDetails(false)
            setTimeout(() => {
                setSelectedSnapshot()
            }, 100);
        }
        };

        document.addEventListener('click', handleClick, true);

        return () => {
        document.removeEventListener('click', handleClick, true);
        };
    }, [refModal]);

    const activeTab = (index) => {
        setSelectedDetail(index)
    }

    const { locId: locationId, apiKey: custom_agencyApiKey } = extractParams();

    // get snapshots list
    const [loading, data, error] = useHttpRequest({
        method: 'post',
        url: Routes.SNAPSHOTS_LIST,
        body: {
          locationId,
          custom_agencyApiKey,
        },
    });

    useEffect(() => {
      if(data){
        setSnapshots(data?.filter(val => val.type === types[props.seletedSnapshots]))
        setIsLoading(false)
      }
    }, [data, props.seletedSnapshots])
    

    // update snapshot
    const clickProceed = async () => {

        setUpdating(true)

        const update = await httpRequest(Routes.SNAPSHOT_UPDATE, 'post', null, {
            locationId,
            updId: props.locID,
            custom_agencyApiKey,
            snapShotId: selectedSnapshot.id
        })

        if(update){
            setIsOpen(false)
            setUpdating(false)
        }
    }

    const renderEmpty = (message) => {
        return(
            <>
                <img src={NothingFound} alt="" style={{ marginTop: 50 }} />
                <p>
                    {message}
                </p>
            </>
        )
    }

    const renderDetails = () => {
        return(
            <div 
                style={{ 
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    left: 0, top: 0,
                    background: '#00000050',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 100,
                }}
            >
                <div 
                    ref={refModal}
                    style={{
                        width: '70%',
                        height: '70%',
                        background: 'white',
                        zIndex: 100,
                        padding: 20,
                        borderRadius: 10,
                        overflowY: 'scroll',
                        overflowX: 'hidden'
                    }}
                >
                    <div 
                        style={{ 
                            display: 'flex', alignItems:'center', 
                        }}
                    >
                        <div style={{ display: 'flex', marginTop: '10px', marginBottom: 15, minWidth: '98%' }}>
                            {
                                [
                                    {name: 'Overview', tab: 'one'},
                                    {name: 'Example Facebook ads', tab: 'two'}, 
                                    {name: 'Setup guide', tab: 'three'}
                                ].map((val, ind) => (
                                    <div 
                                        key={val.name+ind}
                                        onClick={() => {
                                            activeTab(ind)
                                        }}
                                        style={{
                                            marginRight: 10,
                                            cursor: 'pointer',
                                            borderBottom: selectedDetail === ind ? '2px solid #000000' : '',
                                            transition: '0.1s'
                                        }}
                                    >
                                        <span style={{ fontSize: 14, fontWeight: selectedDetail === ind ? 600 : 300 }}>
                                            {val.name}
                                        </span>
                                    </div>
                                ))
                            }
                        </div>
                        <div
                            style={{ cursor: 'pointer', minWidth: 500 }}
                            onClick={() => { 
                                setOpenSnapshotDetails(false) 
                                setSelectedDetail(0)
                            }}
                        >
                            <FiX size={23} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {
                            selectedDetail === 0 ? 
                                <div style={{ textAlign: 'center' }}>
                                    {
                                        (selectedSnapshot?.video === null && selectedSnapshot?.overview_text === null) ? 
                                            renderEmpty('Overview not available!')
                                        :
                                         <>
                                            {
                                                selectedSnapshot?.video ? 
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <ReactPlayer controls url={selectedSnapshot?.video} />
                                                    </div>
                                                :
                                                    null
                                            }
                                            <div style={{ textAlign: 'left', padding: '0 10px' }}>
                                                {selectedSnapshot?.overview_text ? parse(selectedSnapshot?.overview_text) : ""}
                                            </div>
                                         </>
                                    }
                                </div>
                            :
                                selectedDetail === 1 ?
                                    <div style={{ height: 340, textAlign: 'center' }}>
                                        {
                                            selectedSnapshot?.facebook_example ?
                                                <img 
                                                    src={selectedSnapshot?.facebook_example} alt="" 
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        background: 'lightgrey'
                                                    }}
                                                />
                                            :
                                                renderEmpty('Facebook ads example not available!')
                                        }
                                    </div>
                                :
                                    <div style={{ width: '100%', padding: 20 }}>
                                        {
                                            selectedSnapshot?.setup_guilde ? 
                                                <div>
                                                    {parse(selectedSnapshot?.setup_guilde) }
                                                </div>
                                            : 
                                                <div style={{ textAlign: 'center', }}>
                                                    {renderEmpty('Setup guide not available!')}
                                                </div>
                                        }
                                    </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    const modalContent = () => {
        return(
            <>
                <div style={{ display: 'flex', alignItems:'center', minWidth: 300 }}>
                    <FiAlertTriangle 
                        size={22}
                        color={colors.warning}
                    />
                    <h4 style={{ width: '100%', marginLeft: 10 }}>
                        Warning
                    </h4>
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => { setIsOpen(false) }}
                    >
                        <FiX size={23} />
                    </div>
                </div>
                {
                    updating ?
                        <div style={{ minWidth: 500, height: 200, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                            <Oval
                                height="23"
                                width="23"
                                color={colors.green}
                                ariaLabel='loading'
                            />
                            <p>
                                Updating snapshot...
                            </p>
                        </div>
                    :
                        <>
                            <div>
                                <p style={{ lineHeight: '25px' }}>
                                    Updating snapshots can overwrite data, If you're sure you want to update it, please type in <strong>confirm</strong> then click proceed.
                                </p>
                                <input 
                                    value={confirm}
                                    onChange={(event)=>{
                                        setConfirm(event.target.value)
                                    }}
                                    style={{
                                        width: 200, height: 45,
                                        border: '1px solid lightgrey',
                                        borderRadius: 8, fontSize: 16,
                                        margin: '0 auto', padding: '0 15px'
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex', alignItems: 'center',
                                    justifyContent: 'flex-end', width: '100%',
                                    marginTop: 20
                                }}
                            >
                                <Button
                                    onClick={()=>{
                                        setIsOpen(false)
                                    }}
                                    style={{
                                        marginRight: 10,
                                        border: `1px solid ${colors.warning}`,
                                        background: 'none'
                                    }}
                                >
                                    <p style={{ color: colors.warning, margin: 0 }}>
                                        Cancel
                                    </p>
                                </Button>
                                <Button
                                    onClick={()=>{
                                        if(confirm === "Confirm" || confirm === "confirm"){
                                            clickProceed()
                                        }
                                    }}
                                    style={{
                                        border: 'none',
                                        background: (confirm === "Confirm" || confirm === "confirm") ? colors.lightGreen : "lightgrey"
                                    }}
                                >
                                    <p
                                        style={{
                                            color: (confirm === "Confirm" || confirm === "confirm") ? colors.green : "white",
                                            margin: 0
                                        }}
                                    >
                                        Proceed
                                    </p>
                                </Button>
                            </div>
                        </>
                }
            </>
        )
    }

    if(data === null || error){
        return null
    }

    return(
        isLoading ? 
            <div 
                style={{ 
                    width: '100%', height: 300,  
                    display: 'flex', alignItems: 'center', justifyContent: 'center' 
                }}
            >
                <CircularProgress sx={{ color: colors.warning }} />
            </div>
        :
            <div ref={refScrollable} style={{ marginTop: -100, paddingTop: 100 }}>
                <GridContainer>
                {
                    snapshots?.length === 0 ?
                    <div className="empty-list">
                        <img src={EmptyIllu} alt="" />
                        <h4>
                            There are no imported Snapshots!
                        </h4>
                    </div>
                    :
                    !!snapshots && snapshots?.map((item, index) => {
                        return(
                            <Item key={item.name+index}>
                                {(openSnapshotDetails && selectedSnapshot?.id === item?.id) && renderDetails()}
                                <div style={{ position: 'relative', width: '100%', height: '100%', }}>
                                    <Row>
                                        <div className="illustration">
                                            <img src={item.image ? item.image : Placeholder} alt=""/>
                                        </div>
                                        <p>
                                            {item.name}
                                        </p>
                                    </Row>
                                    <div className="actions">
                                        <Button
                                            onClick={()=>{
                                                setIsOpen(true)
                                                setSelectedSnapshot(item)
                                            }}
                                        >
                                            <p style={{ fontWeight: 700, fontSize: 14 }}>
                                                Select
                                            </p>
                                            <FiArrowRight color="#FFFFFF" />
                                        </Button>
                                        <Button 
                                            style={{
                                                background: '#F21F21',
                                                marginTop: 10
                                            }}
                                            onClick={() => {
                                                setSelectedSnapshot(item)
                                                setTimeout(() => {
                                                    setOpenSnapshotDetails(true)
                                                    refScrollable?.current?.scrollIntoView()   
                                                }, 200);
                                            }}
                                        >
                                            <p style={{ fontSize: 13 }}>
                                                See more
                                            </p>
                                            <FiEye color="#FFFFFF"/>
                                        </Button>
                                    </div>
                        
                                    {modalIsOpen && <Modal
                                        isOpen={modalIsOpen}
                                        onRequestClose={()=>setIsOpen(false)}
                                        onAfterClose={()=>{
                                            setSelectedSnapshot()
                                            props.closeSnapshotModal()
                                        }}
                                        style={customStyles}
                                        contentLabel="SelectModal"
                                        aria={{
                                            labelledby: "item_title",
                                            describedby: "item_info"
                                        }}
                                    >
                                        {modalContent()}
                                    </Modal>}
                                </div>
                            </Item>
                        )
                    })
                }
                </GridContainer>
            </div>
    )
}

export default SnapshotsList;
