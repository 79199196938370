import styled from "styled-components";

export const TextInput = styled.input`
    width: 100%;
    max-width: ${props => props.maxWidth ? props.maxWidth : '300px'};
    min-width: 250px;
    height: 45px;
    padding: 0 15px;
    border: 1px solid lightgrey;
    border-radius: 10px
`

export const TextArea = styled.textarea`
    width: 100%;
    min-width: 250px;
    padding: 15px;
    border: 1px solid lightgrey;
    border-radius: 10px
`