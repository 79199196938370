import axios from "axios";

const baseURL = "https://scripts.systems.leadific.io";
// export const baseURL = "http://localhost:4444";

const DefaultHeaders = {};

export const httpRequest = async (url, method, headers, data) => {
  try {
    const response = await axios.request({
      baseURL,
      url,
      method,
      headers: {
        ...DefaultHeaders,
        ...headers,
      },
      data,
    });
    return response.data;
  } catch (err) {
    return { status: false, message: `${JSON.stringify(err)}`, error: err };
  }
};
