import React, {useState, useEffect, useCallback} from "react";
import { Routes, colors, permissionErrorText, permissionErrorTitle } from "../constants";
import useHttpRequest from "../api/actions";
import { extractParams } from "../helpers";
import { ErrorView, Loader } from "../views";
import { TextInput } from '../components/textInput'
import styled from "styled-components";
import { FiCheck, FiCheckCircle, FiSettings, FiTrash, FiUpload, FiXCircle } from 'react-icons/fi'
import Modal from 'react-modal';
import { httpRequest } from '../api/axios'
import { Oval } from  'react-loader-spinner'
import SaasDomainModal from '../components/saasDomainModal'
import {useDropzone} from 'react-dropzone'

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxHeight: 500
    },
    overlay: {
        background: '#00000080'
    }
};

Modal.setAppElement('#root');

const Container = styled.div`
    padding: 20px;
    max-width: 700px;
    margin: 0 auto;
    margin-top: 100px;
    box-shadow: 0px 5px 10px #eeeeee;
    border-radius: 10px;
    & .row {
        display: flex; align-items: center;
    };
    & .button {
        display: flex; align-items: center;
        justify-content: center;
        width: 45px; height: 45px;
        min-width: 45px; min-height: 45px;
        border-radius: 10px;
        background: #eeeeee;
        margin-left: 15px;
        cursor: pointer;
    };
    & .label {
        width: 100%
    };
    & h2 {
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 30px;
    };
    & .image-tabs {
        padding: 20px;
        border: 1px solid lightgrey;
        margin-bottom: 20px;
        border-radius: 10px;
        border-top-left-radius: 0
    };
    & .tabs {
        display: flex;
        align-items: center;
    };
    & .tabs>div {
        cursor: pointer;
        width: 100px; height: 50px;
        display: flex; align-items: center;
        justify-content: center;
        border-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: -1px;
        border: 1px solid #eeeeee;
        border-bottom: 0
    };
    & .dropzone {
        min-height: 100px;
        display: flex; align-items: center;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        border-radius: 10px;
        border: 1px dashed ${props => props ? colors.lightGreen : ''};
        padding: 15px
    }
`

const Content = styled.div`
    padding: 15px;
    display: flex;
    & .setting-row {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #eeeeee
    };
    & .setting-row>p:first-child {
        width: 100px;
        opacity: 0.6
    }
`

const Button = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background: ${props => props ? colors?.lightGreen : ''};
    border-radius: 20px;
    cursor: pointer;
    & p {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        color: ${props => props ? colors?.green : ''}
    }
`

const Divider = styled.div`
    background: ${props => props ? colors?.Gray2 : ''};
    height: 1px;
    width: 100px;
    margin: 20px 0;
`

export const SettingsScreen = () => {

    const [uploadType, setUploadType] = useState('logo')
    const [imgUrl, setImgUrl] = useState('')
    const [favUrl, setFavUrl] = useState('')
    const [updatingImage, setUpdatingImage] = useState(false)
    const [updatingFavicon, setUpdatingFavicon] = useState(false)
    const [files, setFiles] = useState([]);
    const [favFiles, setFavFiles] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0)
    const [selectedTabFav, setSelectedTabFav] = useState(0)
    const [domain, setDomain] = useState('')
    const [whiteLabelTitle, setwhiteLabelTitle] = useState('')
    const [domainSettings, setDomainSettings] = useState()
    const [uploadingImage, setUploadingImage] = useState(false)
    const [uploadingFav, setUploadingFav] = useState(false)
    const [deletingDomain, setDeletingDomain] = useState(false)
    const [deletDomainStatus, setDeletDomainStatus] = useState({
        status: null,
        message: ''
    })
    const [uploadImageMessage, setUploadImageMessage] = useState('')
    const [uploadFavMessage, setUploadFavMessage] = useState('')
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [confirmationIsOpen, setConfirmationIsOpen] = React.useState(false);
    const [openSaas, setIsOpenSaas] = React.useState(false);
    const { locId: locationId, apiKey: custom_agencyApiKey } = extractParams();
    const urlRegex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');

    const onDrop = useCallback(acceptedFiles => {
        if(uploadType === 'logo'){
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))); 
        }else{
            setFavFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
        uploadImage(acceptedFiles[0])
      }, [uploadType])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({accept: 'image/*', onDrop})

    useEffect(() => {
        // Revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    useEffect(() => {
        // Revoke the data uris to avoid memory leaks
        favFiles.forEach(file => URL.revokeObjectURL(file.preview));
    }, [favFiles]);    

    // get settings data
    const [loading, data, error] = useHttpRequest({
      method: 'post',
      url: Routes.GET_SETTINGS,
      body: {
        locationId,
        custom_agencyApiKey,
      },
    });

    useEffect(() => {
      setDomain(data?.saasDomain)
      setImgUrl(data?.image)
      setFavUrl(data?.favicon)
      setwhiteLabelTitle(data?.whiteLabelTitle ?? 'Welcome !')
    }, [data])
    
    // check if string is url
    const validateURL = (value) => {
        if (!urlRegex.test(value)) {
           return false
        } else {
            return true
        }
    };

    // update settings
    const updatesettings = async ()  => {

        const update = await httpRequest(Routes.UPDATE_SETTINGS, 'post', null, {
            locationId,
            custom_agencyApiKey,
            whiteLabelTitle
        })

        if(update){
            setwhiteLabelTitle(update?.whiteLabelTitle)
        }
    }

    // update logo image using url
    const updateImage = async ()  => {
        if(uploadType === 'logo'){
            setUpdatingImage(true)
        }else{
            setUpdatingFavicon(true)
        }

        const update = await httpRequest(Routes.UPDATE_IMAGE, 'post', null, {
            locationId,
            custom_agencyApiKey,
            image: imgUrl,
            type: uploadType
        })

        if(update){
            if(uploadType === 'logo'){
                setUpdatingImage(false)
                if(update?.status){
                    setImgUrl(update?.data?.url)
                } 
            }else{
                setUpdatingFavicon(false)
                if(update?.status){
                    setFavUrl(update?.data?.url)
                } 
            }
        }
    }

    // upload new logo image from device
    const uploadImage = async (file)  => {
        if(uploadType==='logo'){
            setUploadingImage(true)
        }else{
            setUploadingFav(true)
        }

        const formData = new FormData()
        formData.append("locationId", locationId)
        formData.append("custom_agencyApiKey", custom_agencyApiKey)
        formData.append("file", file)
        formData.append("type", uploadType)
        const upload = await httpRequest(Routes.UPLOAD_IMAGE, 'post', { "Content-Type": "multipart/form-data" }, formData)

        if(upload){
            if(uploadType === 'logo'){
                setUploadingImage(false)
                setImgUrl(upload?.data?.url)
                if(upload?.status){
                    setUploadImageMessage(upload?.message)
                    setImgUrl(upload?.data?.url)
                }
            }else{
                setUploadingFav(false)
                setFavUrl(upload?.data?.url)
                if(upload?.status){
                    setUploadFavMessage(upload?.message)
                    setFavUrl(upload?.data?.url)
                }
            }
        }

    }  
    
    // delete saas domain
    const domainDelete = async ()  => {

        setDeletingDomain(true)

        const deleteDomain = await httpRequest(Routes.DOMAIN_DELETE, 'post', null, {
            locationId,
            custom_agencyApiKey,
        })

        if(deleteDomain){
            setDeletingDomain(false)

            if(deleteDomain?.status){
                setDeletDomainStatus({
                    status: true,
                    message: deleteDomain?.message
                })
                setDomain('')
                setTimeout(() => {
                    setConfirmationIsOpen(false)
                    setDeletDomainStatus({
                        status: null,
                        message: ''
                    })
                }, 2000);
            }else{
                setDeletDomainStatus({
                    status: false,
                    message: deleteDomain?.message
                })
                setTimeout(() => {
                    setConfirmationIsOpen(false)
                    setDeletDomainStatus({
                        status: null,
                        message: ''
                    })
                }, 2000);
            }
        }

    }  
    
    const updateDNSModal = () => {
        return(
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={()=>setIsOpen(false)}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <h2
                    style={{
                        fontWeight: '500',
                        marginTop: 0,
                        textTransform: 'uppercase'
                    }}
                >
                    Update dns settings
                </h2>
                <Content>
                    
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'left',
                        }}
                    >
                        <div className="setting-row">
                            <p>
                                Name
                            </p>
                            <p >
                                {domainSettings?.name}
                            </p>
                        </div>
                        <div className="setting-row">
                            <p>
                                Type
                            </p>
                            <p >
                                {domainSettings?.type}
                            </p>
                        </div>
                        <div className="setting-row">
                            <p>
                                Value
                            </p>
                            <p >
                                {domainSettings?.value}
                            </p>
                        </div>
                    </div>
                </Content>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    <Button
                        onClick={()=>{
                            setIsOpen(false)
                        }}
                    >
                        <p>
                            Continue
                        </p>
                    </Button>
                </div>
            </Modal>
        )
    }

    const confirmationModal = () => {
        return(
            <Modal
                isOpen={confirmationIsOpen}
                onRequestClose={()=>setConfirmationIsOpen(false)}
                style={customStyles}
                onAfterClose={() => {
                    setDeletDomainStatus({
                        status: null,
                        message: ''
                    })
                }}
                contentLabel="Example Modal"
            >
                <h2
                    style={{
                        fontWeight: '500',
                        marginTop: 0,
                        textTransform: 'uppercase'
                    }}
                >
                    Delete domain
                </h2>
                <div style={{ margin: '20px 0' }}>
                    {
                        deletingDomain ? 
                            <div 
                                style={{ 
                                    display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                                    width: 300
                                }}
                            >
                                <Oval
                                    height="35"
                                    width="35"
                                    color={colors.Gray2}
                                    ariaLabel='loading'
                                />
                                <p>
                                    Deleting domain...
                                </p>
                            </div>
                        :
                            deletDomainStatus.status === null ?
                                <p>
                                    Are you sure you want to delete this domain!
                                </p>
                            :
                                deletDomainStatus.status ?
                                    <div 
                                        style={{ 
                                            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                                            width: 300
                                        }}
                                    >
                                        <FiCheckCircle size={35} color={colors.green}/>
                                        <p>
                                            {deletDomainStatus.message}
                                        </p>
                                    </div>
                                :
                                    <div 
                                        style={{ 
                                            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                                            width: 300
                                        }}
                                    >
                                        <FiXCircle size={35} color={colors.red}/>
                                        <p>
                                            {deletDomainStatus.message}
                                        </p>
                                    </div>
                    }
                </div>
                {!deletingDomain && <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    {deletDomainStatus.status === null && <Button
                        onClick={()=>{
                            setConfirmationIsOpen(false)
                        }}
                        style={{
                            marginRight: 10, 
                            background: colors.Gray2
                        }}
                    >
                        <p style={{ margin: 0, color: "black", opacity: 0.5 }}>
                            Cancel
                        </p>
                    </Button>}
                    <Button
                        onClick={()=>{
                            if(deletDomainStatus.status === null){
                                domainDelete()
                            }else{
                                setConfirmationIsOpen(false)
                            }
                        }}
                        style={{
                            minWidth: 50,
                        }}
                    >
                        {
                            deletDomainStatus.status === null ?
                                <p>
                                    Continue
                                </p>
                            :
                                <p style={{ textAlign: 'center', width: '100%' }}>
                                    ok
                                </p>                            
                        }
                    </Button>
                </div>}
            </Modal>
        )
    }

    // permission denied view
    if (!locationId || !custom_agencyApiKey || error || data?.authorized === false){
        return (
            <ErrorView
                message={permissionErrorTitle}
                description={permissionErrorText}
            />
          );
    }

    // loader view
    if (loading) return <Loader />;

    // main view
    return (
        <div style={{ paddingBottom: 100 }}>
            <Container>
                <h2>
                    Update White Label SaaS Settings
                </h2>

                <div className="tabs">
                    {
                        ["LOGO", "Upload"].map((val, ind) => {
                            return(
                                <div 
                                    key={ind}
                                    onClick={()=>{
                                        setUploadType('logo')
                                        setSelectedTab(ind)
                                        setSelectedTabFav(0)
                                        setUploadImageMessage('')
                                    }}
                                    style={{
                                        background: ind === selectedTab ? 'white' : '',
                                        borderColor: ind === selectedTab ? 'lightgrey' : '#eeeeee',
                                    }}
                                >
                                    <span 
                                        style={{ 
                                            fontSize: 14, opacity: ind === selectedTab ? '1' : '0.7',
                                            fontWeight: ind === selectedTab ? '500' : '300',
                                        }} 
                                    >
                                        {val}
                                    </span>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="image-tabs">
                    {
                        selectedTab === 0 ?
                            <div className="row">
                                <p className="label">
                                    Logo url
                                </p>
                                <TextInput 
                                    value={imgUrl}
                                    onChange={(event)=>{
                                        setImgUrl(event.target.value)
                                    }}
                                />
                                <div 
                                    className="button" 
                                    style={{ background: (imgUrl && validateURL(imgUrl)) ? colors.lightGreen : '' }}
                                    onClick={()=>{
                                        if(imgUrl && validateURL(imgUrl)){
                                            updateImage()
                                        }
                                    }}
                                >
                                    {
                                        updatingImage ? 
                                            <Oval
                                                height="23"
                                                width="23"
                                                color={colors.green}
                                                ariaLabel='loading'
                                            />
                                        :
                                            <FiCheck size={23} color={(imgUrl && validateURL(imgUrl)) ? colors.green : 'lightgrey'} />
                                    }
                                </div>
                            </div>
                        :
                            <div style={{ position: 'relative' }}>
                                {uploadingImage && <div style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#7272721f', borderRadius: 10 }}>
                                    <Oval 
                                        height="30"
                                        width="30"
                                        color={colors.green}
                                        ariaLabel='loading'
                                    />
                                </div>}
                                <div className="dropzone" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <FiUpload size={30} color={isDragActive ? colors.green : 'lightgrey'} />
                                    {
                                        isDragActive ?
                                            <p>Drop the image here ...</p> 
                                        :
                                            <div
                                                style={{ textAlign: 'center', marginTop: 15, fontSize: 14 }}
                                            >
                                                <span>Drag 'n' drop some files here,</span><br/><br/>
                                                <small>Or</small><br/>
                                                <p style={{ color: colors.green, background: colors.lightGreen, padding: 10, borderRadius: 10 }} >
                                                    click to select files
                                                </p>
                                            </div>
                                    }

                                </div>
                                {uploadImageMessage && <p style={{ fontSize: 14, color: colors.warning }}>{uploadImageMessage}</p>}
                            </div>
                    }
                </div>

                <Divider />

                <div className="tabs">
                    {
                        ["FAVICON", "Upload"].map((val, ind) => {
                            return(
                                <div 
                                    key={ind}
                                    onClick={()=>{
                                        setUploadType('favicon')
                                        setSelectedTabFav(ind)
                                        setSelectedTab(0)
                                        setUploadImageMessage('')
                                    }}
                                    style={{
                                        background: ind === selectedTabFav ? 'white' : '',
                                        borderColor: ind === selectedTabFav ? 'lightgrey' : '#eeeeee',
                                    }}
                                >
                                    <span 
                                        style={{ 
                                            fontSize: 14, opacity: ind === selectedTabFav ? '1' : '0.7',
                                            fontWeight: ind === selectedTabFav ? '500' : '300',
                                        }} 
                                    >
                                        {val}
                                    </span>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="image-tabs">
                    {
                        selectedTabFav === 0 ?
                            <div className="row">
                                <p className="label">
                                    Favicon url
                                </p>
                                <TextInput 
                                    value={favUrl}
                                    onChange={(event)=>{
                                        setFavUrl(event.target.value)
                                    }}
                                />
                                <div 
                                    className="button" 
                                    style={{ background: (favUrl && validateURL(favUrl)) ? colors.lightGreen : '' }}
                                    onClick={()=>{
                                        if(favUrl && validateURL(favUrl)){
                                            updateImage()
                                        }
                                    }}
                                >
                                    {
                                        updatingFavicon ? 
                                            <Oval
                                                height="23"
                                                width="23"
                                                color={colors.green}
                                                ariaLabel='loading'
                                            />
                                        :
                                            <FiCheck size={23} color={(favUrl && validateURL(favUrl)) ? colors.green : 'lightgrey'} />
                                    }
                                </div>
                            </div>
                        :
                            <div style={{ position: 'relative' }}>
                                {uploadingFav && <div style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#7272721f', borderRadius: 10 }}>
                                    <Oval 
                                        height="30"
                                        width="30"
                                        color={colors.green}
                                        ariaLabel='loading'
                                    />
                                </div>}
                                <div className="dropzone" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <FiUpload size={30} color={isDragActive ? colors.green : 'lightgrey'} />
                                    {
                                        isDragActive ?
                                            <p>Drop the image here ...</p> 
                                        :
                                            <div
                                                style={{ textAlign: 'center', marginTop: 15, fontSize: 14 }}
                                            >
                                                <span>Drag 'n' drop some files here,</span><br/><br/>
                                                <small>Or</small><br/>
                                                <p style={{ color: colors.green, background: colors.lightGreen, padding: 10, borderRadius: 10 }} >
                                                    click to select files
                                                </p>
                                            </div>
                                    }

                                </div>
                                {uploadFavMessage && <p style={{ fontSize: 14, color: colors.warning }}>{uploadFavMessage}</p>}
                            </div>
                    }
                </div>

                <Divider />
                
                <div className="row">
                    <p className="label">
                        SaaS Hostname
                    </p>
                    <TextInput 
                        value={domain}
                        onChange={(event)=>{
                            setDomain(event.target.value)
                        }}
                        disabled
                    />

                    <div 
                        className="button" 
                        style={{ background: domain ? colors.lightRed : colors.lightGreen }}
                        onClick={()=>{
                            if(domain){
                                setConfirmationIsOpen(true)
                            }else{
                                setIsOpenSaas(true)
                            }
                        }}
                    >
                        {domain ? <FiTrash size={23} color={colors.red} /> : <FiSettings size={23} color={colors.green} />}
                    </div>
                </div>
                <div className="row">

                <p className="label">
                        Title
                    </p>
                    <TextInput 
                        value={whiteLabelTitle}
                        onChange={(event)=>{
                            setwhiteLabelTitle(event.target.value)
                        }}
                    />

                    <div 
                        className="button" 
                        style={{ background: colors.lightGreen  }}
                        onClick={()=>{
                            updatesettings(whiteLabelTitle)
                        }}
                    >
                        {<FiCheck size={23} color={colors.green} />}
                    </div>
                </div>

                <SaasDomainModal open={openSaas} close={()=>setIsOpenSaas(false)} />

                {updateDNSModal()}

                {confirmationModal()}
            </Container>
        </div>
    );
};

export default SettingsScreen;
