import * as React from "react";
import Grid from "@mui/material/Grid";
import { colors } from "../constants";
import CircularProgress from "@mui/material/CircularProgress";

export const Loader = () => {
  return (
    <div className="App">
      <Grid
        container
        direction={"column"}
        style={{
          minHeight: "100%",
          backgroundColor: colors.Gray2,
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item style={{ marginBottom: "2vh" }}>
          <CircularProgress sx={{ color: colors.warning }} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Loader;
